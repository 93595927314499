import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import styled from "styled-components";
import axios from "axios";
import { Code } from "react-content-loader";
import NiceModal from "../../modals/NiceModal";
import GroupsAPI from "../../../api/GroupsAPI";
import UpdateGroupForm from "../forms/UpdateGroupForm";
import CommonHelper from "../../../helpers/CommonHelper";

import pencil from "../../../assets/images/edit_grey.svg";
import DoctorDashboardPanel from "../../dashboard/panels/DoctorDashboardPanel";
import { useMappedState } from "redux-react-hook";
import GroupStatsTableTool from "../tools/GroupStatsTableTool";
import { isDebugMode } from "../../../helpers/UiHelper";
import { CORSANO_API_ENDPOINT } from "../../../constants/config";
import GroupEcgExportTool from "../tools/GroupEcgExportTool";

export default function GroupPanel(props) {
  const { code } = props;

  // console.debug("optimizing rendering GroupPanel.js");
  const [group, setGroup] = useState();

  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addModalVisible, setAddModalVisible] = useState(false);

  const [editId, setEditId] = useState(undefined);
  const [ecgReportVisible, setEcgReportVisible] = useState(false);

  const { currentUserObj, email, isDemo, theme } = useMappedState(
    useCallback((state) => {
      let email = state?.auth?.currentUserObj?.usersUser?.email;
      return {
        currentUserObj: state.auth.currentUserObj,
        email: email,
        isDemo: email == "demo@corsano.com_",
        theme: state.ui.theme,
      };
    })
  );

  let xUUID = currentUserObj?.usersUser?.uuid;

  useEffect(() => {
    setLoading(true);
    GroupsAPI.getGroups().then((arr) => {
      setGroups(arr.sort((a, b) => +b.timestamp - +a.timestamp));
      setLoading(false);
      // console.debug("optimizing GroupsAPI.getGroups loaded");
    });
  }, []);

  let isDebug = isDebugMode();

  if (loading == true && groups.length == 0) {
    // console.debug("optimizing GroupPanel loading");
    return (
      <div>
        {/* <h1>LOADING groupsPanel</h1> */}
        {theme === "dark" ? (
          <>
            <Code backgroundColor={"#333"} foregroundColor={"#999"} />
            <Code backgroundColor={"#333"} foregroundColor={"#999"} />
            <Code backgroundColor={"#333"} foregroundColor={"#999"} />
          </>
        ) : (
          <>
            <Code />
            <Code />
            <Code />
          </>
        )}
      </div>
    );
  }

  let editGroup =
    editId == undefined ? undefined : groups.filter((x) => x.id == editId)[0];

  return (
    <Wrapper>
      <DoctorDashboardPanel
        isGwMode={code == undefined || (code != undefined && code.length > 24)}
        tag={code || xUUID}
      />

      {isDebug == false || code == undefined ? null : (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span
            style={{
              cursor: "pointer",
              fontSize: "12px",
              fontStyle: "italic",
              opacity: 0.5,
            }}
            onClick={() => {
              setEcgReportVisible(true);
            }}
          >
            ECG records report
          </span>
        </div>
      )}

      {ecgReportVisible == false ? null : (
        <NiceModal
          onClose={() => {
            setEcgReportVisible(false);
          }}
        >
          <InnerPlaceholder>
            <GroupEcgExportTool code={code} />
          </InnerPlaceholder>
        </NiceModal>
      )}
    </Wrapper>
  );
}

const InnerPlaceholder = styled.div`
  width: 720px;
  min-height: 500px;
  max-height: calc(min(100vh - 200px, 820px));
  overflow-y: auto;
`;

const ItemBottomPlaceholder = styled.div`
  opacity: 0.8;
  font-size: 12px;
  font-style: italic;
`;

const NamePlaceholder = styled.div`
  color: #000f4b;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 1px;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`;

const CodePlaceholder = styled.div`
  color: #000f4b;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 1px;
  opacity: 0.7;
`;

const AddInnerPlaceholder = styled.div`
  width: 600px;
  box-sizing: border-box;
  padding: 20px;
`;

const EditImg = styled.img`
  cursor: pointer;
  opacity: 0.7;
  height: 20px;
  margin-left: 10px;

  :hover {
    opacity: 1;
  }
`;

const Wrapper = styled.div``;

const Heading = styled.div`
  color: #000f4b;
  font-weight: bold;
  font-size: 36px;
  line-height: 45px;
  letter-spacing: 1px;
`;

const AddButton = styled.div`
  background: #147aff;
  height: 46px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  color: white;
  padding-left: 25px;
  padding-right: 25px;
  font-size: 14px;
  font-weight: 800;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

const TopPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const ItemTopPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ItemLeft = styled.div`
  flex: 1;
`;

const ItemRight = styled.div`
  margin-left: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const GroupItem = styled.div`
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid rgba(135, 152, 173, 0.15);
  box-shadow: ${(props) =>
    props.danger == true ? "0px 4px 5px rgba(250, 70, 70, 0.05);" : "none"};
  box-sizing: border-box;
  margin-bottom: 20px;
  padding: 20px;
  display: flex;
  flex-direction: row;
`;

const GroupsListPlaceholder = styled.div``;
